<template>
	<app tabbar>
		<tab-list-m :tab="tabList" @change="updateList"></tab-list-m>
		<scroll-list-m ref="list">
			<template v-slot="{data}">
				<scroll-item-m>
					<div class="flex-between-center">
						<div class="info">申请时间：{{data.create_date}}</div>
						<div class="state"><span :style="{'color': proceedState[data.state].color}">{{proceedState[data.state].name}}</span></div>
					</div>
					<div class="name">{{data.project_name}}</div>
					<div class="info">施工单位：{{data.zbqy_name}}</div>
					<div class="info">监理单位：{{data.jldw_name}}</div>
					<button-list-m slot="button">
						<c-button v-if="data.state == 2" @click="navigateTo('/proceed_detail', {id: data.id})">审批</c-button>
						<c-button v-else color="gray" hollow @click="navigateTo('/proceed_detail', {id: data.id})">查看详情</c-button>
					</button-list-m>
				</scroll-item-m>
			</template>
		</scroll-list-m>
	</app>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		data() {
			return {
				tabList: [{
					name: '待审批开工令',
					param: {
						state: 2
					}
				}, {
					name: '开工令列表',
				}]
			}
		},
		
		computed: {
			...mapGetters(['proceedState'])
		},
		
		methods: {
			updateList(data) {
				this.$refs.list.load({
					url: '/project/kgl/list',
					data: data.param
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>